import React, { useEffect, useState } from "react";
import { Badge, Spin, Calendar as AntCalendar } from "antd";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { isoDateRange } from "../../utils/functions.js";
import { SUBMISSION_STATUS_LIST } from "../../constants/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { calendarQuery } from "./queries.js";
import { DateDetail } from "./date-detail.js";
import { MonthCell } from "./month-cell.js";
import { createDueRange, restructureToDosData, showClickForMore } from "./helpers.js"; 
import { PersistentState } from "../../utils/PersistentState.js";
import dayjs from "dayjs";

const { usePersistentState } = PersistentState();

const calendarLoading = () => {
    return (
        <>
            <Spin size="medium" style={{position: "relative", top: "300px"}}>
                <AntCalendar />
            </Spin>
        </>
    );
};

export const CalendarContent = ({ 
    shouldReset,
    containerLoaded,
    permissions, 
    submissionDueRange,
    setSubmissionDueRange,
    submissionTypesFilter,
    organizationsFilter,
    statusFilter,
    categoryFilter
}) => {
    const [dateDetail, setDateDetail] = useState({ scale: "date" });

    // persistent state :: 
    const [yearView, setYearView] = usePersistentState(false);
    const [selectedDateValue, setSelectedDateValue] = usePersistentState(dayjs(moment()));

    const queryVariables = {
        submissionDueRange: isoDateRange(submissionDueRange),
        toDoDateRangeFilter: isoDateRange(submissionDueRange),
        submissionTypesFilter,
        organizationsFilter,
        categoryFilter,
        statusFilter
    };
    
    const { loading, error, data } = useQuery(calendarQuery, {
        variables: {
            ...queryVariables, 
            yearView
        },
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (shouldReset) {
            setSelectedDateValue(dayjs(moment()));
            setYearView(false);
        }
    }, [shouldReset]);

    if (error) {
        return handleGraphQLError(error);
    }

    const calendarIsLoading = loading || !containerLoaded || !data;

    if (calendarIsLoading) {
        return calendarLoading();
    }

    const { 
        canFilterByOrganization
    } = permissions;

    const toDos = restructureToDosData(data?.calendar?.toDos);

    const submissions = data?.calendar?.submissions;

    const getListData = (selectedDate, scale, providedData = null) => {
        const listData = [];

        const submissionsToUse = providedData?.submissions ?? submissions;
        const toDosToUse = providedData?.toDos ?? toDos;

        submissionsToUse.forEach((doc) => {
            if (moment(selectedDate).isSame(moment(doc.reviewerDueDate), scale)) {
                const action = (doc.status === SUBMISSION_STATUS_LIST.review || doc.status === SUBMISSION_STATUS_LIST.final_version_review) ? "Review" :
                    "";

                let actionText = `${action}: ${doc.specifier}`;
                if (canFilterByOrganization) {
                    actionText += ` (${doc.submitter.name})`;
                }

                listData.push({
                    type: "submission-rev",
                    color: "cyan",
                    text: actionText,
                    href: `/submissions/${doc.id}/`,
                    submissionType: `${doc?.submissionType?.name ?? ""}`,
                    dueDate: doc.reviewerDueDate
                });
            }

            if (moment(selectedDate).isSame(moment(doc.submitterDueDate), scale)) {
                const action = (doc.status === SUBMISSION_STATUS_LIST.version ? "Resubmit" :
                    (doc.status === SUBMISSION_STATUS_LIST.final_requested ? "Submit Final" :
                        ""));

                let actionText = `${action}: ${doc.specifier}`;
                if (canFilterByOrganization) {
                    actionText += ` (${doc.submitter.name})`;
                }

                listData.push({
                    type: "submission-sub",
                    color: action === "Resubmit" ? "yellow" : "pink",
                    text: actionText,
                    href: `/submissions/${doc.id}/`,
                    submissionType: `${doc?.submissionType?.name ?? ""}`,
                    dueDate: doc.submitterDueDate
                });
            }
        });

        toDosToUse.forEach((toDo) => {
            const toDoDueDate = toDo.timeline.currentDueDate;

            if (moment(selectedDate).isSame(moment(toDoDueDate), scale)) {
                let actionText = `Submit: ${toDo.obligation.submissionType.specifier} - ${toDo.contract.contractType.name}`;
                if (canFilterByOrganization) {
                    actionText += ` (${toDo.contract.promisor.name})`;
                }

                listData.push({
                    type: "toDo",
                    color: "red",
                    text: actionText,
                    toDo: toDo,
                    submissionType: `${toDo?.obligation?.submissionType?.name ?? ""}`,
                    dueDate: toDoDueDate
                });
            }
        });

        return listData;
    };

    const onSelect = (value, scale) => {
        setDateDetail({
            ...dateDetail,
            scale: scale ? scale : "date",
            date: value.format("MM/DD/YYYY"),
            vis: true
        });
    };

    const onPanelChange = (selectedDate, scale) => {
        // when switching from month to year view, or vice versa, need to sync up with current date :: 
        const changingOver = scale === "month" && yearView || scale === "year" && !yearView;
        const dateToUse = changingOver ? moment() : selectedDate;
        const newRange = createDueRange(dateToUse, scale);
        setSubmissionDueRange(newRange);
        setYearView((scale === "year"));
        setSelectedDateValue(dayjs(dateToUse));
    };

    const dateCellRender = (value) => {
        const listData = getListData(value, "date");
        return (
            <div onClick={() => onSelect(value)}>
                <ul className="events noPadding">
                    {listData.map((item, i) => {
                        return (
                            <li key={`item-content-event-day-${i}`}>
                                <Badge color={item.color} text={item.text} />
                            </li>
                        );
                    })}
                    { showClickForMore(listData) }
                </ul>
            </div>
        );
    };

    const monthCellRender = (date) => {
        return (
            <MonthCell 
                onSelect={onSelect}
                date={date}
                getListData={getListData}
                queryVariables={queryVariables}
            />
        );
    };

    const cellRender = (current, info) => {
        if (info.type === "date") {
            return dateCellRender(current);
        }
        if (info.type === "month") {
            return monthCellRender(current);
        }
        return info.originNode;
    };

    return (
        <>
            <AntCalendar
                cellRender={cellRender}
                onSelect={(value) => setSelectedDateValue(dayjs(value))}
                value={selectedDateValue}
                onPanelChange={onPanelChange}
                mode={yearView === true ? "year" : "month"}
                validRange={[moment([2019]), moment().add(10, "years")]}
                className="calendar-selector"
            />
            <DateDetail 
                dateDetail={dateDetail}
                setDateDetail={setDateDetail}
                queryVariables={queryVariables}
                getListData={getListData}
            />
        </>
    );
};
