import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Pagination, Radio, Form } from "antd";
import { Table } from "../../common/Table/index.js";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from "../../Application/UserContext.js";
import { searchable } from "../../../utils/index.js";
import {
    CREATE_BUSINESS_UNIT,
    MODIFY_BUSINESS_UNIT
} from "../../../constants/mutations.js";
import { Main } from "../../common/Main/index.js";
import { EditableDetail } from "../../common/EditableDetail/index.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { handleGraphQLError, handleMutation, handleMutations } from "../../../utils/errorHandling.js";
import { businessUnitSettingsQuery } from "./queries.js";
import { ClearFiltersButton } from "../../common/ClearFiltersButton/index.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";
import { usePreloadedData } from "../../Application/PreloadedData/index.js";

const { confirm } = Modal;


export const BusinessUnitSettings = () => {
    const defaultSortOn = "name";
    const defaultSortBy = "ascend";
    const { refetchPreloadedData } = usePreloadedData();
    const [businessUnitSelection, setBusinessUnitSelection] = useState([]);
    const [businessUnitTablePage, setBusinessUnitTablePage] = useState(1);
    const [businessUnitTablePageSize, setBusinessUnitTablePageSize] = useState(10);
    const [modified, setModified] = useState({});
    const [createBusinessUnit] = useMutation(CREATE_BUSINESS_UNIT);
    const [modifyBusinessUnit] = useMutation(MODIFY_BUSINESS_UNIT);
    const [businessUnitModalVis, setBusinessUnitModalVis] = useState(false);
    const [businessUnitModify, setBusinessUnitModify] = useState(false);
    const [sortOn, setSortOn] = useState(defaultSortOn);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [activeFilter, setActiveFilter] = useState(false);
    const [nameSearch, setNameSearch] = useState("");
    const [businessUnitTitle, setBusinessUnitTitle] = useState("Create a New Business Unit");
    const [dataToUse, setDataToUse] = useState(null);

    const {
        userPermCreateBusinessUnits
    } = useContext(UserContext);

    const clearSearch = () => {
        setBusinessUnitTablePage(1);
        setBusinessUnitTablePageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setActiveFilter(false);
        setNameSearch("");
        setBusinessUnitSelection([]);
    };

    const { loading, error, data, refetch } = useQuery(businessUnitSettingsQuery, {
        fetchPolicy: "no-cache",
        variables: {
            offset: (businessUnitTablePage - 1) * businessUnitTablePageSize,
            limit: businessUnitTablePageSize,
            activeFilter,
            nameSearch,
            sortOn,
            sortBy
        }
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (!dataToUse) {
        return (
            <LoadingContent />
        );
    }

    if (error) {
        return handleGraphQLError(error);
    }
    const {
        businessUnitsList
    } = dataToUse;
    const { businessUnits, count: businessUnitCount } = businessUnitsList;

    const showConfirmBusinessUnit = (id, deleted) => {
        const action = deleted ? "De-archive" : "Archive";
        confirm({
            title:
                `Are you sure you want to ${action} this Business Unit?`,
            okText: `Yes, ${action}`,
            async onOk() {
                await handleMutation(
                    modifyBusinessUnit({
                        variables: {
                            id,
                            deleted: !deleted
                        }
                    })
                    , {
                        showSuccess: true,
                        successMessage: `Business Unit successfully ${activeFilter ? "de-archived" : "archived"}`
                    });
                if (businessUnits.length === 1) {
                    setBusinessUnitTablePage(1);
                }
                await refetch();
                refetchPreloadedData();
            }
        });
    };

    const rightAlignedContent = userPermCreateBusinessUnits
        ? <Radio.Group
            className="ownLine"
            defaultValue={activeFilter}
            value={activeFilter}
            onChange={(e) => {
                setActiveFilter(e.target.value);
                setBusinessUnitTablePage(1);
                setBusinessUnitSelection([]);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={false} >Active Business Units</Radio.Button>
            <Radio.Button value={true}>Inactive Business Units</Radio.Button>
        </Radio.Group>
        : null;

    const businessUnitTable = (
        <>
            <Table
                rightAlignedButtonBarElement={rightAlignedContent}
                clearFiltersButton={<ClearFiltersButton
                    clearFilters={clearSearch}
                    filtersData={[
                        { currentValue: activeFilter, defaultValue: false },
                        { currentValue: nameSearch, defaultValue: "" },
                        { currentValue: businessUnitSelection, defaultValue: [] }
                    ]}
                />}
                style={{
                    backgroundColor: "#fff"
                }}
                size="middle"
                pagination={false}
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    searchable({
                        title: "Business Unit Name",
                        dataIndex: "name",
                        handleSearch: (textToSearch) => {
                            setNameSearch(textToSearch);
                            setBusinessUnitTablePage(1);
                        },
                        searchedText: nameSearch,
                        width: "30%",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"]
                    }),
                    {
                        title: "Status",
                        dataIndex: "deleted",
                        key: "status",
                        render: (deleted) => {
                            return deleted ? "Inactive" : "Active";
                        },
                        width: "40%"
                    },
                    userPermCreateBusinessUnits ? {
                        title: "Actions",
                        key: "actions",
                        width: "30%",
                        render: ({ id, name, deleted }) => {
                            return (
                                <div>
                                    <Button
                                        type="primary"
                                        className="spaceBelow-xs btn btn-sm btn-primary"
                                        style={{ marginRight: "5px" }}
                                        size="small"
                                        onClick={() => {
                                            setModified({
                                                ...modified,
                                                businessUnitName: name,
                                                businessUnitId: id
                                            });
                                            setBusinessUnitModalVis(true);
                                            setBusinessUnitModify(true);
                                            setBusinessUnitTitle(`Modify Category for ${name}`);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        type="danger"
                                        size="small"
                                        onClick={() => {
                                            showConfirmBusinessUnit(id, deleted);
                                        }}
                                    >
                                        {deleted ? "De-Archive" : "Archive"}
                                    </Button>
                                </div>
                            );
                        }
                    } : {}
                ])}
                rowKey="id"
                dataSource={businessUnits}
                rowSelection={userPermCreateBusinessUnits && {
                    selectedRowKeys: businessUnitSelection,
                    onChange: (selectedRowKeys) => {
                        setBusinessUnitSelection(selectedRowKeys);
                    }
                }}
                loading={loading}
                onChange={(_pagination, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                }}
            />
            <Modal
                title={businessUnitTitle}
                open={businessUnitModalVis}
                destroyOnClose="true"
                onOk={async () => {
                    if (businessUnitModify) {
                        await handleMutation(
                            modifyBusinessUnit({
                                variables: {
                                    id: modified.businessUnitId,
                                    businessUnitName: modified.businessUnitName
                                }
                            }),
                            {
                                showSuccess: true
                            }
                        );
                        setBusinessUnitModify(false);
                        setBusinessUnitModalVis(false);
                        setModified({});
                        await refetch();
                        refetchPreloadedData();
                    }
                    else {
                        await handleMutation(
                            createBusinessUnit({
                                variables: {
                                    businessUnitName: modified.businessUnitName
                                }
                            })
                        );
                        setBusinessUnitModalVis(false);
                        setModified({});
                        await refetch();
                        refetchPreloadedData();
                    }
                }}
                okText="Save"
                okButtonProps={{
                    disabled: typeof modified.businessUnitName === "undefined"
                }}
                onCancel={() => {
                    setModified({});
                    setBusinessUnitModalVis(false);
                    setBusinessUnitModify(false);
                }}
            >
                <Form
                    layout="vertical"
                    autoComplete="off"
                >
                    <EditableDetail
                        title="Business Unit Name"
                        key="businessUnitName"
                        value={modified.businessUnitName}
                        onValueUpdated={(e) => {
                            setModified({
                                ...modified,
                                businessUnitName: e.target.value
                            });
                        }}
                    />
                </Form>
            </Modal>
        </>
    );


    const businessUnitTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setBusinessUnitTablePage(targetPage);
                setBusinessUnitTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setBusinessUnitTablePage(targetPage);
                setBusinessUnitTablePageSize(pageSize);
            }}
            current={businessUnitTablePage}
            defaultCurrent={businessUnitTablePage}
            pageSize={businessUnitTablePageSize}
            defaultPageSize={businessUnitTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={businessUnitCount}
        />
    );

    const showConfirmArchive = () => {
        const businessUnitsNames = businessUnits
            .filter(({ id }) => businessUnitSelection.includes(id))
            .map(({ name }) => name);

        confirm({
            title:
                `Do you really want to ${activeFilter ? "De-Archive" : "Archive"} ${businessUnitSelection.length} ${businessUnitSelection.length === 1 ? "Business Unit" : "Business Units"}: ${businessUnitsNames.join(", ")
                }?`,
            okText: "Yes",
            async onOk() {
                const mutations = businessUnitSelection.map((id) => {
                    return modifyBusinessUnit({
                        variables: {
                            id,
                            deleted: !activeFilter
                        }
                    });
                });

                // ERROR HANDLER ITERATION 
                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${businessUnitSelection.length === 1 ? "Business Unit" : "Business Units"} successfully ${activeFilter ? "de-archived" : "archived"}`
                });
                if (businessUnits.length === businessUnitSelection.length) {
                    setBusinessUnitTablePage(1);
                }
                await refetch();
                setBusinessUnitSelection([]);
                refetchPreloadedData();
            }
        });
    };

    const createBusinessUnitButton = <Button
        type="primary"
        style={{
            float: "right",
            display: userPermCreateBusinessUnits ? "inline-block" : "none"
        }}
        onClick={() => {
            setBusinessUnitModalVis(true);
            setBusinessUnitTitle("Create a  New Business Unit");
        }}
    >
        Create Business Unit
    </Button>;

    return (
        <Main
            title="Business Units"
            breadcrumbs={
                ["Settings", "Business Units"]
            }
            details={createBusinessUnitButton}
        >
            <div className="main-content-margin-top">
                <Button
                    style={{
                        display: userPermCreateBusinessUnits ? "initial" : "none",
                        marginBottom: "10px"
                    }}
                    className="ownLine"
                    type="danger"
                    size="small"
                    onClick={showConfirmArchive}
                    disabled={businessUnitSelection.length <= 0}
                >
                    {activeFilter ? "De-Archive" : "Archive"} {businessUnitSelection.length} {businessUnitSelection.length === 1 ? "Business Unit" : "Business Units"}
                </Button>
            </div>
            {businessUnitTable}
            {businessUnitTablePagination}
        </Main>
    );
};