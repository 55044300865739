import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Pagination, Radio } from "antd";
import { useNavigate } from "react-router-dom";
import { Table } from "../../common/Table/index.js";
import { ClearFiltersButton } from "../../common/ClearFiltersButton/index.js";
import { useMutation, useQuery } from "@apollo/client";
import { UserContext } from "../../Application/UserContext.js";
import { searchable } from "../../../utils/index.js";
import { MODIFY_CATEGORY } from "../../../constants/mutations.js";
import { Main } from "../../common/Main/index.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { handleGraphQLError, handleMutation, handleMutations } from "../../../utils/errorHandling.js";
import { categoriesSettingsQuery } from "./queries.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";
import { PCDULink } from "../../common/PCDULink/index.js";

const { confirm } = Modal;

export const CategorySettings = ({ refetchSideNavCategories, setCategoryListRefetch }) => {
    const defaultSortOn = "name";
    const defaultSortBy = "ascend";
    const navigate = useNavigate();
    const [categorySelection, setCategorySelection] = useState([]);
    const [categoryTablePage, setCategoryTablePage] = useState(1);
    const [categoryPageTableSize, setCategoryPageTableSize] = useState(10);
    const [modifyCategory] = useMutation(MODIFY_CATEGORY);
    const [activeFilter, setActiveFilter] = useState(false);
    const [sortOn, setSortOn] = useState(defaultSortOn);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [nameSearch, setNameSearch] = useState("");
    const [dataToUse, setDataToUse] = useState(null);

    const immutableSubmissionTypeCategories = (record) => {
        return (record.editableFields.length <= 0) ? true : false;
    };

    const {
        userPermCreateSubTypeCategory
    } = useContext(UserContext);

    const clearSearch = () => {
        setCategoryTablePage(1);
        setCategoryPageTableSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setActiveFilter(false);
        setNameSearch("");
        setCategorySelection([]);
    };

    const { loading, error, data, refetch } = useQuery(categoriesSettingsQuery, {
        fetchPolicy: "no-cache",
        variables: {
            offset: (categoryTablePage - 1) * categoryPageTableSize,
            limit: categoryPageTableSize,
            activeFilter,
            nameSearch,
            sortOn,
            sortBy
        }
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (!dataToUse) {
        return (
            <LoadingContent />
        );
    }

    if (error) {
        return handleGraphQLError(error);
    }

    const {
        categoriesList
    } = dataToUse;
    const { categories, count: categoriesCount } = categoriesList;

    const archiveNoteContent = (deleted) => {
        return (
            <span style={{
                color: deleted ? "green" : "red"
            }}>
                Note: {deleted ? "De-" : ""}Archiving Category will {deleted ? "de-" : ""}archive all the Submission Type(s) associated with it
            </span>
        );
    };

    const showConfirmCategory = (id, deleted) => {
        const action = deleted ? "De-Archive" : "Archive";
        confirm({
            title: `Are you sure you want to ${action} this category?`,
            okText: `Yes, ${action}`,
            content: archiveNoteContent(deleted),
            async onOk() {
                await handleMutation(
                    modifyCategory({
                        variables: {
                            categoryInput: {
                                id,
                                deleted: !deleted
                            }
                        }
                    }), 
                    {
                        showSuccess: true,
                        successMessage: `Category successfully ${activeFilter ? "de-archived" : "archived"}`
                    });
                if (categories.length === 1) {
                    setCategoryTablePage(1);
                }
                await refetch();
                setCategoryListRefetch(true);
                refetchSideNavCategories();
            }
        });
    };

    const showConfirmArchive = () => {
        const categoriesNames = categories
            .filter(({ id }) => categorySelection.includes(id))
            .map(({ name }) => name);

        confirm({
            title:
                `Do you really want to ${activeFilter ? "De-Archive" : "Archive"} following ${categorySelection.length === 1 ? "Category" : "Categories"}?`,
            content: (
                <>
                    <ul>
                        {categoriesNames.map((name) => {
                            return (
                                <li>
                                    {name}
                                </li>
                            );
                        })}
                    </ul>
                    {archiveNoteContent(activeFilter)}
                </>
            ),
            okText: "Yes",
            async onOk() {
                const mutations = categorySelection.map((id) => {
                    return modifyCategory({
                        variables: {
                            categoryInput: {
                                id,
                                deleted: !activeFilter
                            }
                        }
                    });
                });
                // ERROR HANDLER ITERATION 
                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${categorySelection.length === 1 ? "Category" : "Categories"} successfully ${activeFilter ? "de-archived" : "archived"}`
                });
                if (categories.length === categorySelection.length) {
                    setCategoryTablePage(1);
                }
                await refetch();
                setCategorySelection([]);
                setCategoryListRefetch(true);
                refetchSideNavCategories();
            }
        });
    };

    const rightAlignedContent = userPermCreateSubTypeCategory
        ? <Radio.Group
            defaultValue={activeFilter}
            value={activeFilter}
            onChange={
                (e) => {
                    setActiveFilter(e.target.value);
                    setCategoryTablePage(1);
                    // Clear check box selection on Button change
                    setCategorySelection([]);
                }
            }
            buttonStyle="solid"
            className="ownLine"
        >
            <Radio.Button value={false} >Active Categories</Radio.Button>
            <Radio.Button value={true}>Inactive Categories</Radio.Button>
        </Radio.Group>
        : null;

    const submissionCategoryTable = (
        <>
            <Table
                clearFiltersButton={<ClearFiltersButton
                    clearFilters={clearSearch}
                    filtersData={[
                        { currentValue: activeFilter, defaultValue: false },
                        { currentValue: nameSearch, defaultValue: "" }
                    ]}
                />}
                rightAlignedButtonBarElement={rightAlignedContent}
                pagination={false}
                columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                    searchable({
                        title: "Category Name",
                        dataIndex: "name",
                        handleSearch: (textToSearch) => {
                            setNameSearch(textToSearch);
                            setCategoryTablePage(1);
                        },
                        searchedText: nameSearch,
                        width: "30%",
                        sorter: true,
                        render: (name, { id }) => {
                            return (
                                <PCDULink 
                                    to={`/submission-types/category/category-detail/${id}`}
                                    state={{"editCategory": false}}
                                >
                                    {name}
                                </PCDULink>
                            );
                        },
                        sortDirections: ["ascend", "descend", "ascend"]
                    }),
                    {
                        title: "Status",
                        dataIndex: "deleted",
                        key: "status",
                        render: (deleted) => {
                            return deleted ? "Inactive" : "Active";
                        },
                        width: "40%"
                    },
                    userPermCreateSubTypeCategory ? {
                        title: "Actions",
                        key: "actions",
                        width: "30%",
                        render: (record) => {
                            const { id, deleted } = record;
                            return (
                                <div>
                                    {/* don't allow editing of an archived submission type category */}
                                    {!deleted && <Button
                                        type="primary"
                                        className="spaceBelow-xs btn btn-sm btn-primary"
                                        style={{ marginRight: "5px" }}
                                        size="small"
                                        disabled={immutableSubmissionTypeCategories(record)}
                                        onClick={() => {
                                            navigate(`/submission-types/category/category-detail/${id}`, { state: {"editCategory": true}});
                                        }}
                                    >
                                        Edit
                                    </Button>}
                                    <Button
                                        type="danger"
                                        size="small"
                                        disabled={immutableSubmissionTypeCategories(record)}
                                        onClick={() => {
                                            showConfirmCategory(id, deleted);
                                        }}
                                    >
                                        {deleted ? "De-Archive" : "Archive"}
                                    </Button>
                                </div>
                            );
                        }
                    } : {}
                ])}
                rowKey="id"
                dataSource={categories}
                rowSelection={userPermCreateSubTypeCategory && {
                    selectedRowKeys: categorySelection,
                    onChange: (selectedRowKeys) => {
                        setCategorySelection(selectedRowKeys);
                    },
                    getCheckboxProps: (record) => ({
                        disabled: immutableSubmissionTypeCategories(record),
                        name: record.name
                    })
                }}
                loading={loading}
                onChange={(_pagination, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                }}
            />

        </>
    );

    const categoriesTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setCategoryTablePage(targetPage);
                setCategoryPageTableSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setCategoryTablePage(targetPage);
                setCategoryPageTableSize(pageSize);
            }}
            current={categoryTablePage}
            defaultCurrent={categoryTablePage}
            pageSize={categoryPageTableSize}
            defaultPageSize={categoryPageTableSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={categoriesCount}
        />
    );

    const createSubmissionTypeCategoryButton = userPermCreateSubTypeCategory
        ? <div>
            <PCDULink
                className="ant-btn ant-btn-primary link-button"
                to={{
                    pathname: "/submission-types/category/create"
                }}
                style={{
                    float: "right",
                    display: "inline-block"
                }}
            >
                Create Category
            </PCDULink>
        </div>
        : null;

    return (
        <Main
            title="Categories"
            breadcrumbs={
                ["Settings", "Categories"]
            }
            details={createSubmissionTypeCategoryButton}
        >
            <div className="main-content-margin-top">
                <Button
                    style={{
                        display: userPermCreateSubTypeCategory ? "initial" : "none",
                        marginBottom: "10px"
                    }}
                    type="danger"
                    size="small"
                    onClick={showConfirmArchive}
                    disabled={categorySelection.length <= 0}
                >
                    {activeFilter ? "De-Archive" : "Archive"} {categorySelection.length} {categorySelection.length === 1 ? "Category" : "Categories"}
                </Button>
                {submissionCategoryTable}
                {categoriesTablePagination}
            </div>
        </Main>
    );
};
