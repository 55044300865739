import moment from "moment";
import React, { useState, useContext } from "react";
import { Badge, Divider, Modal, Pagination, Spin } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { formatDate, isoDateRange } from "../../utils/functions.js";
import { UploadSubmissionDocument } from "../common/UploadSubmissionDocument/index.js";
import { useQuery } from "@apollo/client";
import { dateDetailQuery } from "./queries.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { ErrorMessage } from "../ErrorMessage/index.js";
import { CALENDAR_BREADCRUMB, createDueRange, restructureToDosData } from "./helpers.js";

const TO_DOS_TEXT = "Upcoming submissions (scheduled)";
const MINIMUM_MODAL_WIDTH = "700px";
const SUBMISSIONS_TEXT = "Current submissions";

const truncateTo30 = (str) => typeof str !== "string" ? "" : (str.length > 30 ? `${str.slice(0, 27).trim()}...` : str);

// this helps smooth out loading ui in the date detail modal when changing pages or page size :: 
const nullList = (length, listname = "generic") => {
    const arr = [];
    const lengthToUse = typeof length === "number" && length > 0 && length <= 200 ? length : 10;
    for (let i = 0; i < lengthToUse; i++) {
        arr.push(`null-list-${listname}-${i}`);
    }
    return arr.map(k => <div style={{ width: "10px", height: "30px" }} key={k}></div>);
};

const mapDataToMarkup = ({
    userOrganizationId,
    dateDetail,
    permissions
}) => {

    const { userIsReviewerOrNotifier, canCreateSubmission } = permissions;

    return (item, i) => {
        const itemDateDisplay = dateDetail.scale === "month"
            ? `(${formatDate(item.dueDate)})`
            : "";

        const itemText = `${item.text} - ${truncateTo30(item?.submissionType)} ${itemDateDisplay}`;

        const showLinkToItem = (userIsReviewerOrNotifier && ["cyan"].includes(item.color)) ||
            (canCreateSubmission && ["red", "yellow", "pink"].includes(item.color));

        return (
            <div
                key={`list-data-${i}`}
                className="spaceBelow-sm"
            >
                {showLinkToItem
                    ? (item.toDo
                        ? (
                            <div 
                                style={
                                    {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start"
                                    }
                                }
                            >
                                
                                <Badge color={item.color} 
                                
                                />
                                <UploadSubmissionDocument
                                    fromCalendar={true}
                                    buttonTitle={itemText}
                                    isDisable={true}
                                    style={{
                                        "border": "0",
                                        "color": "#1890ff",
                                        "marginBottom": "0px",
                                        "boxShadow": "none",
                                        "background": "#FFFFFF"
                                    }}
                                    buttonStyle={{
                                        size: "small"
                                    }}
                                    documentUploadTitle={item.toDo.obligation.submissionType.packagedSubmission ? `Upload New Document(s) for ${item.toDo.obligation.submissionType.name}` : `Upload New Document for ${item.toDo.obligation.submissionType.name}`}
                                    createData={
                                        {
                                            days: item.toDo.obligation.submissionType?.daysToReview,
                                            obligationId: item.toDo?.obligation?.id,
                                            organizationId: userOrganizationId,
                                            contractId: item.toDo?.contract?.id,
                                            timelineId: item.toDo?.timeline.id,
                                            submissionTypeId: item.toDo.obligation.submissionType?.id,
                                            currentDueDate: item.toDo?.timeline.currentDueDate
                                        }
                                    }
                                    create={true}
                                    submissionTypeId={item.toDo.obligation.submissionType?.id}
                                    useAttestation={item.toDo.obligation.submissionType.useAttestation}
                                    submissionTypeData={item.toDo.obligation}
                                    submissionDocument={item.toDo.obligation.submissionType}
                                    maxDocumentCount={item.toDo.obligation.submissionType?.packagedSubmission ? 100 : 1}
                                    callBackMethod={
                                        () => {
                                            dateDetail.vis = false;
                                        }
                                    }
                                    dueDateModelDisplay={true}
                                    newBreadcrumbs={CALENDAR_BREADCRUMB}
                                />
                                
                            </div>
                        )
                        : (
                            <div>
                                <Badge color={item.color} />
                                <PCDULink 
                                    to={item.href} style={{ margin: "0 7px" }} state={{ from: "calendar" }}
                                    newBreadcrumbs={CALENDAR_BREADCRUMB}
                                >
                                    {itemText}
                                </PCDULink>
                            </div>
                        )
                    )
                    : (
                        <div>
                            <Badge color={item.color} />
                            {itemText}
                        </div>
                    )
                }
            </div>
        );
    };
};

export const DateDetail = ({
    queryVariables,
    dateDetail,
    setDateDetail,
    getListData
}) => {

    const [submissionsPageSize, setSubmissionsPageSize] = useState(10);
    const [toDosPageSize, setToDosPageSize] = useState(10);
    const [toDosPage, setToDosPage] = useState(1);
    const [submissionsPage, setSubmissionsPage] = useState(1);
    const {
        userPermCreateSubmission, 
        userIsReviewerOrNotifier,
        userOrganizationId
    } = useContext(UserContext);


    const clearOut = () => {
        setSubmissionsPageSize(10);
        setToDosPageSize(10);
        setToDosPage(1);
        setSubmissionsPage(1);
    };

    const offsetSubmissions = (submissionsPage - 1) * submissionsPageSize;
    const offsetToDos = (toDosPage - 1) * toDosPageSize;

    const theDate = moment(dateDetail.date);

    const dateRange = createDueRange(theDate, dateDetail.scale);

    const { data, error, loading } = useQuery(dateDetailQuery, {
        variables: {
            ...queryVariables,
            submissionDueRange: isoDateRange(dateRange),
            toDoDateRangeFilter: isoDateRange(dateRange),
            limitSubmissions: submissionsPageSize,
            offsetSubmissions,
            limitToDos: toDosPageSize,
            offsetToDos
        },
        fetchPolicy: "no-cache"
    });

    if (dateDetail.vis && (error || loading)) {
        return (
            <Modal
                title={`Due ${dateDetail.scale === "month" ? `in ${theDate.format("MMMM YYYY")}` : `on ${theDate.format("MM/DD/YYYY")}`}`}
                open={dateDetail.vis}
                style={{ minWidth: MINIMUM_MODAL_WIDTH }}
                footer={null}
                onCancel={() => {
                    clearOut();
                    setDateDetail({ scale: dateDetail.scale });
                }}
            >
                <>
                    {error && <ErrorMessage
                        title={"There was an error loading data for this date."}
                    />}
                    {loading && <Spin> {nullList(toDosPageSize + submissionsPageSize)} </Spin>}
                </>
            </Modal>
        );
    }

    const submissionsCount = data?.submissionsList?.count ?? 0;
    const toDosCount = data?.toDos?.[0]?.count ?? 0;

    const submissionsToDisplay = getListData(theDate, dateDetail.scale, {
        submissions: data?.submissionsList?.submissions ?? [],
        toDos: []
    });

    const toDosToDisplay = getListData(theDate, dateDetail.scale, {
        submissions: [],
        toDos: data?.toDos ? restructureToDosData(data.toDos) : []
    });

    if (submissionsToDisplay.length === 0 && toDosToDisplay.length === 0) {
        dateDetail.vis = false;
        return null;
    } else {

        const contextData = {
            dateDetail,
            userOrganizationId,
            permissions: {
                canCreateSubmission: userPermCreateSubmission,
                userIsReviewerOrNotifier
            }
        };

        return (
            <Modal
                title={`Due ${dateDetail.scale === "month" ? `in ${theDate.format("MMMM YYYY")}` : `on ${theDate.format("MM/DD/YYYY")}`}`}
                open={dateDetail.vis}
                style={{ minWidth: MINIMUM_MODAL_WIDTH }}
                footer={null}
                onCancel={() => {
                    clearOut();
                    setDateDetail({ scale: dateDetail.scale });
                }}
            >
                <>
                    {submissionsCount > 0 &&
                        <>
                            <div>
                                <p>
                                    <strong>{SUBMISSIONS_TEXT}</strong>
                                </p>
                                {submissionsToDisplay.map(mapDataToMarkup(contextData))}
                                {submissionsCount > 10 && <Pagination
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "20px 0 50px 0"
                                    }}
                                    showSizeChanger
                                    onChange={(targetPage, pageSize) => {
                                        setSubmissionsPage(targetPage);
                                        setSubmissionsPageSize(pageSize);
                                    }}
                                    current={submissionsPage}
                                    pageSize={submissionsPageSize}
                                    pageSizeOptions={["10", "25", "50", "100"]}
                                    total={submissionsCount}
                                />}
                            </div>
                            <Divider />
                        </>
                    }
                    {toDosCount > 0 &&
                        <div 
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden"
                            }}
                        >
                            <p>
                                <strong>{TO_DOS_TEXT}</strong>
                            </p>
                            {toDosToDisplay.map(mapDataToMarkup(contextData))}
                            {toDosCount > 10 && <Pagination
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "20px 0 50px 0"
                                }}
                                showSizeChanger
                                onChange={(targetPage, pageSize) => {
                                    setToDosPage(targetPage);
                                    setToDosPageSize(pageSize);
                                }}
                                current={toDosPage}
                                pageSize={toDosPageSize}
                                pageSizeOptions={["10", "25", "50", "100"]}
                                total={toDosCount}
                            />}
                        </div>
                    }
                </>
            </Modal>
        );
    }
};
