import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Modal, Table, Button, Pagination } from "antd";
import { FREQUENCY_OPTIONS, SUBMISSION_STATUS_LIST } from "../../../constants/index.js";
import { formatDate, isUndefinedOrNull } from "../../../utils/functions.js";
import { handleGraphQLError } from "../../../utils/errorHandling.js";
import { LoadingContent } from "../LoadingContent/index.js";
import { PCDULink } from "../PCDULink/index.js";
import { dueDateModalQuery } from "./query.js";

export const DueDateModal = ({
    options,
    newBreadcrumbs,
    onCancelCallBack,
    onContinueCallback
}) => {
    const {
        nextToDo,
        dueDateModalVis,
        setDueDateListVis
    } = options;

    return (
        <div>
            <Modal
                style={{minWidth: "700px"}}
                title="Submission Due Date"
                open={dueDateModalVis}
                okText="Continue"
                closable={false}
                maskClosable={false}
                className={"due-Date-Modal"}
                onOk={() => {
                    if (onContinueCallback) {
                        onContinueCallback();
                    } else {
                        setDueDateListVis(false);
                    }
                    
                }}
                onCancel={() => {
                    if (onCancelCallBack) {
                        onCancelCallBack();
                    } else {
                        setDueDateListVis(false);
                    }
                    
                }}
                destroyOnClose={true}
            >
                <div>
                    <div style={{fontSize: "14px"}}> 
                        <p>
                            <strong className={"duedate-modal"}>The next due date for this submission type is : {formatDate(nextToDo?.timeline?.currentDueDate)}.</strong>
                            <br></br>
                            <span>To create New Submission for this timeline, click the Continue button.</span>
                        </p>

                        <SubmissionTables newBreadcrumbs={newBreadcrumbs} options={options}></SubmissionTables>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const SubmissionTables = ({
    newBreadcrumbs,
    options
}) => {

    const {
        submissionType,
        nextToDo,
        dueDateModalVis
    } = options;

    const [statusFilter] = useState([
        SUBMISSION_STATUS_LIST.review,
        SUBMISSION_STATUS_LIST.version,
        SUBMISSION_STATUS_LIST.final_version_review,
        SUBMISSION_STATUS_LIST.final_requested
    ]);

    const [sortOn, setSortOn] = useState("createdAt");
    const [sortBy, setSortBy] = useState("ascending");
    const [submissionsPage, setSubmissionsPage] = useState(1);
    const [submissionsPageSize, setSubmissionsPageSize] = useState(10);
    const offsetSubmissions = (submissionsPage - 1) * submissionsPageSize;

    const [getDueDateList, { loading, error, data }] = useLazyQuery(
        dueDateModalQuery,
        {
            fetchPolicy: "no-cache"
        }
    );

    useEffect(() => {
        if (dueDateModalVis) {
            getDueDateList({
                variables: {
                    submissionTypesFilter: [submissionType?.id],
                    statusFilter,
                    offsetSubmissions,
                    limitSubmissions: submissionsPageSize,
                    sortOn,
                    sortBy
                }
            });
        }
    }, [dueDateModalVis, submissionsPage, submissionsPageSize]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading && typeof data === "undefined") {
        return <LoadingContent />;
    }

    const statusOptions = Object.values(SUBMISSION_STATUS_LIST);

    const submissionList = (
        <>
            <Table
                id="submissionsTable"
                size="middle"
                columns={[
                    {
                        title: "ID",
                        key: "specifier",
                        dataIndex: "specifier",
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (text, { id }) => {
                            return (
                                <PCDULink to={`/submissions/${id}`} newBreadcrumbs={newBreadcrumbs}>{text}</PCDULink>
                            );
                        }
                    },
                    {
                        title: "Status",
                        key: "status",
                        dataIndex: "status",
                        domain: statusOptions.map(value => ({
                            label: value,
                            value
                        }))
                    },
                    (submissionType?.submissionTypeNewestVersion?.frequency !== FREQUENCY_OPTIONS.adhoc || !isUndefinedOrNull(nextToDo)) ?
                        {
                            title: "Due Date",
                            key: "currentDueDate",
                            dataIndex: ["timeline", "currentDueDate"],
                            render: (d) => isUndefinedOrNull(d) ? "" : formatDate(d),
                            sortDirections: ["ascend", "descend", "ascend"]
                        }
                        : null,
                    {
                        title: "Document Count",
                        key: "documentCount",
                        render: ({ submissionDocuments }) => {
                            return (
                                submissionDocuments.length
                            );
                        }
                    },
                    {
                        title: "Actions",
                        key: "status",
                        render: ({ status, id }) => {
                            let buttonText = "";
                            switch (status) {
                                case SUBMISSION_STATUS_LIST.version:
                                case SUBMISSION_STATUS_LIST.final_requested:    
                                    buttonText = "SELECT";
                                    break;
                                case SUBMISSION_STATUS_LIST.review:
                                case SUBMISSION_STATUS_LIST.final_version_review:
                                    buttonText = "REVIEW";
                                    break;
                                default:
                                    buttonText = "";
                            }
                            return <Button type="primary" danger>
                                <PCDULink to={`/submissions/${id}`} newBreadcrumbs={newBreadcrumbs}>{buttonText}</PCDULink>
                            </Button>;
                        }
                    }
                ]}
                rowKey="id"
                dataSource={data?.submissionsList?.submissions}
                loading={loading}
                pagination={false}
                onChange={(sorter) => {
                    setSortOn(sorter.columnKey);
                    setSortBy(sorter.order);
                }}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onChange={(targetPage, pageSize) => {
                    setSubmissionsPage(targetPage);
                    setSubmissionsPageSize(pageSize);
                }}
                current={submissionsPage}
                pageSize={submissionsPageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={data?.submissionsList?.count}
            />
        </>
    );

    return (
        <>
            {(data?.submissionsList?.submissions.length > 0) && (
                <>
                    <div className={"duedate-border"}>OR</div>
                    <p>
                        To create a submission for an existing timeline, please review the following submission(s) and
                        select the corresponding action button:
                    </p>
                    <p>
                        <strong>Current Submissions:</strong>
                    </p>
                    {submissionList}
                    
                </>
            )} 
        </>
    );
    
};
